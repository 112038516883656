






























































import {
  ref,
  defineComponent,
  computed,
  useContext,
  useRoute
} from '@nuxtjs/composition-api';
import { SfModal, SfBar } from '@storefront-ui/vue';
import { useUiState } from '~/composables/useUiState';
import { useCart } from '~/modules/core/composables/useCart';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { useForgotPassword } from '~/modules/customer/composables/useForgotPassword';
import { useUser } from '~/modules/customer/composables/useUser';

import LoginForm from '~/modules/customer/components/LoginModal/forms/LoginForm.vue';
import RegisterForm from '~/modules/customer/components/LoginModal/forms/RegisterForm.vue';
import ForgotPasswordForm from '~/modules/customer/components/LoginModal/forms/ForgotPasswordForm.vue';
import ForgotPasswordThankYou from '~/modules/customer/components/LoginModal/forms/ForgotPasswordThankYou.vue';
import type {
  ForgotPasswordFormFields,
  LoginFormFields,
  RegisterFormFields,
  FormName
} from '~/modules/customer/components/LoginModal/forms/types';
import { useGoogleTagManager } from '~/diptyqueTheme/composable';

export default defineComponent({
  name: 'LoginModal',
  components: {
    SfModal,
    SfBar,
    LoginForm,
    RegisterForm,
    ForgotPasswordForm,
    ForgotPasswordThankYou
  },
  setup(_, { emit }) {
    const { isLoginModalOpen } = useUiState();

    const { register, login, loading, error: userError } = useUser();

    const { load: loadCart } = useCart();
    const { loadItemsCount } = useWishlist();
    const {
      request: resetPassword,
      error: forgotPasswordError,
      loading: forgotPasswordLoading
    } = useForgotPassword();
    const route = useRoute();
    const { getLoginDetails } = useGoogleTagManager();

    const currentlyDisplayedForm = ref<FormName>('login');
    const barTitle = computed(() => {
      const mapFormNameToTopBarLabel: Record<FormName, string> = {
        login: 'Sign in',
        register: 'Register',
        forgotPassword: 'Reset Password',
        forgotPasswordThankYou: 'Thank you'
      };

      return mapFormNameToTopBarLabel[currentlyDisplayedForm.value];
    });

    const loginForm = ref<LoginFormFields>({
      email: '',
      password: ''
    });
    const registerForm = ref<RegisterFormFields>({
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      shouldSubscribeToNewsletter: false,
      isWillToCreateAccountConfirmed: false,
    });
    const forgotPasswordForm = ref<ForgotPasswordFormFields>({
      username: ''
    });

    // @ts-expect-error Recaptcha is not registered as a Nuxt module. Its absence is handled in the code
    const { $recaptcha, $config, app: { $gtm } } = useContext();
    const isRecaptchaEnabled = ref<boolean>(
      typeof $recaptcha !== 'undefined' && Boolean($config.isRecaptcha)
    );

    const getRecaptchaInfo = async (
      isRecaptchaOn: boolean
    ): Promise<{ token: string | null; cleanup: () => void }> => {
      if (isRecaptchaOn) {
        $recaptcha.init();
        return {
          token: await $recaptcha.getResponse(),
          cleanup: () => {
            $recaptcha.reset();
          }
        };
      }
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return { token: null, cleanup: () => {} };
    };

    const onLoginFormSubmit = async (form: LoginFormFields) => {
      loginForm.value = form;
      const { token, cleanup } = await getRecaptchaInfo(
        isRecaptchaEnabled.value
      );
      await login({
        user: { ...form, ...(token ? { recaptchaToken: token } : {}) }
      });
      cleanup();
      if (!userError.value.login) {
        $gtm.push(getLoginDetails(route.value, false));
        emit('close');
        await Promise.all([loadItemsCount(), loadCart()]);
      }
    };

    const onRegisterFormSubmit = async (form: RegisterFormFields) => {
      registerForm.value = form;
      const { token, cleanup } = await getRecaptchaInfo(
        isRecaptchaEnabled.value
      );
      await register({
        user: {
          email: form.email,
          firstname: form.firstName,
          lastname: form.lastName,
          password: form.password,
          is_subscribed: form.shouldSubscribeToNewsletter,
          ...(token ? { recaptchaToken: token } : {})
        }
      });
      cleanup();
      if (!userError.value.register) {
        $gtm.push(getLoginDetails(route.value, true));
        emit('close');
        await Promise.all([loadItemsCount(), loadCart()]);
      }
    };

    const onForgotPasswordFormSubmit = async (
      form: ForgotPasswordFormFields
    ) => {
      forgotPasswordForm.value = form;
      const { token, cleanup } = await getRecaptchaInfo(
        isRecaptchaEnabled.value
      );
      await resetPassword({
        email: form.username,
        ...(token ? { recaptchaToken: token } : {})
      });
      cleanup();
      currentlyDisplayedForm.value = 'forgotPasswordThankYou';
    };

    return {
      isLoginModalOpen,

      userError,
      loading,

      forgotPasswordError,
      forgotPasswordLoading,

      currentlyDisplayedForm,
      barTitle,

      loginForm,
      registerForm,
      forgotPasswordForm,

      isRecaptchaEnabled,

      onRegisterFormSubmit,
      onLoginFormSubmit,
      onForgotPasswordFormSubmit
    };
  }
});
