






































































import type { PropType } from '@nuxtjs/composition-api';
// eslint-disable-next-line no-duplicate-imports
import { defineComponent } from '@nuxtjs/composition-api';
import { SfImage, SfLoader, SfTable } from '@storefront-ui/vue';
import VaimoButton from 'atoms/VaimoButton.vue';

import type { SavedPaymentMethod } from './types';

export default defineComponent({
  name: 'PaymentMethodsTable',
  components: {
    VaimoButton,
    SfImage,
    SfTable,
    SfLoader
  },
  props: {
    paymentMethods: {
      type: Array as PropType<Array<SavedPaymentMethod>>,
      default: () => []
    },
    isLoading: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    isDeleting: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  emits: ['delete'],
  setup() {
    const getFormattedDate = (method: SavedPaymentMethod) => {
      if (!method.expiryMonth || !method.expiryYear) return '';

      let year = Number(method.expiryYear);
      let month = Number(method.expiryMonth);
      const CENTURY = 2000;

      if (year > CENTURY) {
        year = year - CENTURY;
      }

      // eslint-disable-next-line no-magic-numbers
      return month.toString().padStart(2, '0') + '/' + year;
    };

    return {
      getFormattedDate,
      tableHeaders: ['Type', 'Credit Card Number', 'Expiration Date', '']
    };
  }
});
