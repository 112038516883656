import { trimString } from '~/diptyqueTheme/helpers/cleanFormData';
import type { CustomerCreateInput } from '~/modules/GraphQL/types';

export const generateUserData = (userData: any): CustomerCreateInput => {
  const baseData = {
    email: trimString(userData.email),
    firstname: trimString(userData.firstName || userData.firstname) || ' ',
    lastname: trimString(userData.lastName || userData.lastname) || ' ',
    mobile: trimString(userData.mobile)
  } as CustomerCreateInput;

  if (Object.prototype.hasOwnProperty.call(userData, 'firstnamekana')) {
    baseData.firstnamekana = trimString(userData.firstnamekana) || ' ';
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'lastnamekana')) {
    baseData.lastnamekana = trimString(userData.lastnamekana) || ' ';
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'is_subscribed')) {
    baseData.is_subscribed = userData.is_subscribed;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'dateOfBirth') || Object.prototype.hasOwnProperty.call(userData, 'date_of_birth')) {
    baseData.date_of_birth = userData.dateOfBirth || userData.date_of_birth;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'gender')) {
    baseData.gender = userData.gender;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'taxvat')) {
    baseData.taxvat = userData.taxvat;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'prefix')) {
    baseData.prefix = userData.prefix;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'suffix')) {
    baseData.suffix = userData.suffix;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'password')) {
    baseData.password = userData.password;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'recaptchaToken')) {
    baseData.recaptchaToken = userData.recaptchaToken;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'mobile')) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    baseData.mobile = userData.mobile;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'optin_mail')) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    baseData.optin_mail = userData.optin_mail;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'optin_newsletter')) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    baseData.optin_newsletter = userData.optin_newsletter;
  }

  if (Object.prototype.hasOwnProperty.call(userData, 'optin_sms')) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    baseData.optin_sms = userData.optin_sms;
  }

  return baseData;
};
