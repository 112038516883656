import type { Ref } from '@nuxtjs/composition-api';
// eslint-disable-next-line no-duplicate-imports
import { computed, useContext } from '@nuxtjs/composition-api';
import { sharedRef } from '@vue-storefront/core';

import { useApi, useConfig, useStore } from '~/composables';
import { useUiNotification } from '~/composables/useUiNotification';
import mask from '~/composables/utils/mask';
import { lsGet, lsRemove } from '~/diptyqueTheme/composable/useLocalStorage';
import getCustomerCartId from '~/diptyqueTheme/customQueries/magento/customerCartId';
import setSocialLoginPassword from '~/diptyqueTheme/customQueries/magento/setSocialLoginPasswordMutation';
import { Logger } from '~/helpers/logger';
import { useCart } from '~/modules/core/composables/useCart';
import { useCartStore } from '~/modules/core/stores/cart';
import { generateUserData } from '~/modules/customer/helpers/generateUserData';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import type { Customer } from '~/modules/GraphQL/types';

import type {
  UseUserChangePasswordParams,
  UseUserChangePasswordSocialsParams,
  UseUserErrors,
  UseUserInterface,
  UseUserLoadParams,
  UseUserLoginParams,
  UseUserLogoutParams,
  UseUserRegisterParams,
  UseUserUpdateUserParams
} from './useUser';
/**
 * Allows loading and manipulating data of the current user.
 *
 * See the {@link UseUserInterface} for a list of methods and values available in this composable.
 */
export function useUser(): UseUserInterface {
  const customerStore = useCustomerStore();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { app, $recaptcha } = useContext();
  const { setCart } = useCart();
  const { setCartInLocalStorageByCode } = useStore();
  const { send: sendNotification } = useUiNotification();
  const cartStore = useCartStore();
  const { config } = useConfig();
  const { mutate } = useApi();
  const errorsFactory = (): UseUserErrors => ({
    updateUser: null,
    register: null,
    login: null,
    logout: null,
    changePassword: null,
    changePasswordSocials: null,
    updateEmail: null,
    load: null
  });
  const user = sharedRef(null, 'useUser-user');
  const loading: Ref<boolean> = sharedRef(false, 'useUser-loading');
  const isAuthenticated = computed(() => Boolean(user.value));
  const error: Ref = sharedRef(errorsFactory(), 'useUser-error');

  const setUser = (newUser: Customer) => {
    customerStore.user = newUser;
    user.value = newUser;
    Logger.debug('useUserFactory.setUser', newUser);
  };

  const resetErrorValue = () => {
    error.value = errorsFactory();
  };

  const updateCustomerEmail = async (credentials: { email: string; password: string }): Promise<void> => {
    try {
      loading.value = true;
      const { data, errors } = await app.context.$vsf.$magento.api.updateCustomerEmail(credentials);
      Logger.debug('[Result]:', { data });
      error.value.updateEmail = null;
      if (errors) {
        const allErrorMessages = errors.map((e) => e.message).join(',');
        Logger.error(allErrorMessages);
        error.value.updateEmail = allErrorMessages;
      }
    } catch (err) {
      error.value.updateEmail = err;
      Logger.error('useUser/updateEmail', err);
    } finally {
      loading.value = false;
    }
  };

  const updateUser = async ({
    user: providedUser,
    customQuery: customQuery = { updateCustomer: 'updateCustomerCustom' }
  }: UseUserUpdateUserParams) => {
    Logger.debug('[Magento] Update user information', { providedUser, customQuery });
    resetErrorValue();

    try {
      loading.value = true;
      const { data, errors } = await app.context.$vsf.$magento.api.updateCustomer(providedUser, customQuery);
      Logger.debug('[Result]:', { data });

      if (errors) {
        const allErrorMessages = errors.map((e) => e.message).join(',');
        Logger.error(allErrorMessages);
        error.value.updateUser = allErrorMessages;
      }

      customerStore.user = data?.updateCustomerV2?.customer || {};
      user.value = data?.updateCustomerV2?.customer || {};
      error.value.updateUser = null;
    } catch (err) {
      error.value.updateUser = err;
      Logger.error('useUser/updateUser', err);
    } finally {
      loading.value = false;
    }
  };

  const handleSocialLoginLogout = () => {
    const strategy = lsGet('strategy');
    if (strategy === 'facebook' && 'FB' in window) {
      window.FB.getLoginStatus((response) => {
        if (response.status === 'connected') {
          window.FB.logout();
          lsRemove('strategy');
        }
      });
    } else if (strategy === 'google' && 'google' in window) {
      window.google.accounts.id.disableAutoSelect();
      lsRemove('strategy');
    }
  };

  const logout = async ({ customQuery = {} }: UseUserLogoutParams = {}) => {
    Logger.debug('[Magento] useUserFactory.logout');
    resetErrorValue();
    const isTokenAvailable = !!app.$vsf.$magento.config.state.getCustomerToken();

    try {
      handleSocialLoginLogout();
      const apiState = app.context.$vsf.$magento.config.state;

      if (isTokenAvailable) {
        await app.context.$vsf.$magento.api.revokeCustomerToken(customQuery);

        apiState.removeCustomerToken();
      }

      apiState.removeCartId();

      setCart(null);
      customerStore.setIsLoggedIn(false);
      customerStore.setIsTokenExpired(true);
      lsRemove('store_cart');
      lsRemove('vsf-checkout');
      error.value.logout = null;
      customerStore.user = null;
      user.value = null;
    } catch (err) {
      error.value.logout = err;
      Logger.error('useUser/logout', err);
    }
  };

  const load = async ({
    customQuery = {
      customer: 'customerWithOptions'
    }
  }: UseUserLoadParams = {}) => {
    Logger.debug('[Magento] useUser.load');
    resetErrorValue();

    try {
      loading.value = true;
      const apiState = app.context.$vsf.$magento.config.state;

      if (!apiState.getCustomerToken()) {
        return null;
      }
      try {
        const { data } = await app.context.$vsf.$magento.api.customer(customQuery);

        Logger.debug('[Result]:', { data });

        customerStore.user = data?.customer ?? {};
        user.value = data?.customer ?? {};
      } catch {
        await logout();
      }
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useUser/load', err);
    } finally {
      loading.value = false;
    }

    return customerStore.user;
  };

  const SECONDS_IN_MINUTE = 60;
  const DEFAULT_JWT_EXPIRATION_MINUTES = 60;

  const setToken = (token: string) => {
    const apiState = app.context.$vsf.$magento.config.state;
    const jwtExpirationMinutes = config.value?.webapi_jwtauth_customer_expiration || DEFAULT_JWT_EXPIRATION_MINUTES;

    apiState.setCustomerToken(token, {
      maxAge: jwtExpirationMinutes * SECONDS_IN_MINUTE
    });
  };

  const login = async ({ user: providedUser, customQuery }: UseUserLoginParams): Promise<void> => {
    Logger.debug('[Magento] useUser.login', providedUser);
    resetErrorValue();

    try {
      loading.value = true;
      cartStore.is_data_loading = true;

      const { data, errors } = await app.$vsf.$magento.api.generateCustomerToken(
        {
          email: providedUser.email,
          password: providedUser.password,
          recaptchaToken: providedUser.recaptchaToken
        },
        customQuery || {}
      );
      Logger.debug('[Result]:', { data });

      if (errors) {
        const joinedErrors = errors.map((e) => e.message).join(',');
        Logger.error(joinedErrors);
        errors.forEach((registerError, i) =>
          sendNotification({
            icon: 'error',
            id: Symbol(`registration_error-${i}`),
            message: app.i18n.t(registerError.message) as string,
            persist: false,
            title: 'Registration error',
            type: 'danger',
            area: 'top'
          })
        );
        throw new Error(joinedErrors);
      }

      customerStore.setIsLoggedIn(true);
      customerStore.setIsTokenExpired(false);
      setToken(data.generateCustomerToken.token);

      await load({
        customQuery: {
          customer: 'customerWithOptions'
        }
      });
      // merge existing cart with customer cart
      // todo: move this logic to separate method
      await mergeCarts();
    } catch (err) {
      error.value.login = err;
      Logger.error('useUser/login', err);
    } finally {
      cartStore.is_data_loading = false;
      loading.value = false;
    }
  };

  const mergeCarts = async () => {
    const apiState = app.context.$vsf.$magento.config.state;
    const currentCartId = apiState.getCartId();
    const cart = await app.context.$vsf.$magento.api.customQuery({ query: getCustomerCartId });
    const newCartId = cart.data.customerCart.id;

    try {
      if (newCartId && currentCartId && currentCartId !== newCartId) {
        const { data: dataMergeCart } = await app.context.$vsf.$magento.api.mergeCarts({
          sourceCartId: currentCartId,
          destinationCartId: newCartId
        });
        setCart(dataMergeCart.mergeCarts);
        apiState.setCartId(dataMergeCart.mergeCarts.id);
        setCartInLocalStorageByCode(dataMergeCart.mergeCarts);
      } else {
        setCart(cart.data.customerCart);
      }
    } catch {
      // Workaround for Magento 2.4.4 mergeCarts mutation error related with Bundle products
      // It can be removed when Magento 2.4.5 will be release
      setCart(cart.data.customerCart);
    }
    error.value.login = null;
  };

  const register = async ({ user: providedUser, customQuery }: UseUserRegisterParams): Promise<void> => {
    Logger.debug('[Magento] useUser.register', providedUser);
    resetErrorValue();

    try {
      loading.value = true;

      const { email, password, recaptchaToken, ...baseData } = generateUserData(providedUser);

      const { data, errors } = await app.$vsf.$magento.api.createCustomer(
        {
          email,
          password,
          recaptchaToken,
          ...baseData
        },
        customQuery || {}
      );

      Logger.debug('[Result]:', { data });

      if (errors) {
        const joinedErrors = errors.map((e) => e.message).join(',');
        Logger.error(joinedErrors);
        errors.forEach((registerError, i) =>
          sendNotification({
            icon: 'error',
            id: Symbol(`registration_error-${i}`),
            message: app.i18n.t(registerError.message) as string,
            persist: false,
            title: 'Registration error',
            type: 'danger',
            area: 'top'
          })
        );
        throw new Error(joinedErrors);
      }
      error.value.register = null;
      let loginRecaptchaToken = '';
      if ($recaptcha && recaptchaToken) {
        loginRecaptchaToken = await $recaptcha.getResponse();
      }

      const {
        customer: { customer_create_account_confirm }
      } = app.context.$vsf.$magento.config;

      if (customer_create_account_confirm) {
        return await new Promise((resolve) => {
          sendNotification({
            id: Symbol('registration_confirmation'),
            message: app.i18n.t('You must confirm your account. Please check your email for the confirmation link.') as string,
            persist: false,
            title: 'Registration confirmation',
            type: 'success',
            icon: 'check',
            area: 'top'
          });

          resolve();
        });
      }
      await login({ user: { email, password, recaptchaToken: loginRecaptchaToken }, customQuery: {} });
    } catch (err) {
      error.value.register = err;
      Logger.error('useUser/register', err);
    } finally {
      loading.value = false;
    }
  };

  const changePassword = async (params: UseUserChangePasswordParams) => {
    Logger.debug('[Magento] useUser.changePassword', { currentPassword: mask(params.current), newPassword: mask(params.new) });
    resetErrorValue();

    try {
      loading.value = true;

      const { data, errors } = await app.context.$vsf.$magento.api.changeCustomerPassword(
        {
          currentUser: customerStore.user,
          currentPassword: params.current,
          newPassword: params.new
        },
        params.customQuery
      );

      let joinedErrors = null;

      if (errors) {
        joinedErrors = errors.map((e) => e.message).join(',');
        Logger.error(joinedErrors);
      }

      Logger.debug('[Result] ', { data });

      customerStore.user = data?.changeCustomerPassword;
      user.value = data?.changeCustomerPassword ?? {};
      error.value.changePassword = joinedErrors;
    } catch (err) {
      error.value.changePassword = err;
      Logger.error('useUser/changePassword', err);
    } finally {
      loading.value = false;
    }
  };

  const changePasswordSocials = async (params: UseUserChangePasswordSocialsParams) => {
    Logger.debug('[Magento] useUser.changePasswordSocials', {
      id: mask(params.id),
      email: mask(params.email),
      newPassword: mask(params.new)
    });
    resetErrorValue();

    try {
      loading.value = true;

      const { data, errors } = await mutate(setSocialLoginPassword, {
        userToken: params.id,
        userEmail: params.email,
        newPassword: params.new
      });

      let joinedErrors = null;
      if (errors) {
        joinedErrors = errors.map((e) => e.message).join(',');
        Logger.error(joinedErrors);
      }
      Logger.debug('[Result] ', { data });
      error.value.changePasswordSocials = joinedErrors;
    } catch (err) {
      error.value.changePasswordSocials = err;
      Logger.error('useUser/changePasswordSocials', err);
    } finally {
      loading.value = false;
    }
  };

  return {
    setUser,
    setToken,
    updateUser,
    register,
    login,
    logout,
    changePassword,
    changePasswordSocials,
    load,
    updateCustomerEmail,
    loading: computed(() => loading.value),
    error: computed(() => error.value),
    user: computed(() => user.value),
    isAuthenticated
  };
}

export default useUser;
export * from './useUser';
