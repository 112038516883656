
































































import {
  computed,
  defineComponent,
  onBeforeMount,
  useContext,
  useRoute,
  useRouter
} from '@nuxtjs/composition-api';
import { SfLink } from '@storefront-ui/vue';
import type { MetaInfo } from 'vue-meta';

import LoyaltyPushRenderer from '~/diptyqueTheme/components/templates/sections/LoyaltyPush/LoyaltyPushRenderer.vue';
import { useScreenSize } from '~/diptyqueTheme/composable/useScreenSize';

import { useUser } from '../../composables/useUser';
import { useSidebarLinkGroups } from './useSidebarLinkGroups';

export default defineComponent({
  name: 'MyAccount',
  components: {
    LoyaltyPushRenderer,
    VaimoHelpBlock: () => import('organisms/VaimoHelpBlock.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    SfLink
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { app, i18n, localeRoute } = useContext();
    const { user: getUser, logout } = useUser();
    const { isMobile } = useScreenSize();
    const LAST_INDEX = -1;

    const getCurrentRouteName = computed(() => route.value.name);

    const { sidebarLinkGroups } = useSidebarLinkGroups();

    const isOnSubpage = computed(() => route.value.matched.length > 1);
    const goToTopLevelRoute = () =>
      router.push(localeRoute({ name: 'customer' }));
    const title = computed(() =>
      i18n.t(route.value.matched.at(LAST_INDEX)?.meta.titleLabel as string)
    );

    const goToPage = (page) => {
      router.push(page);
    };

    const logOutUser = async () => {
      await logout({});

      const homePath = app.localeRoute({ name: 'home' });
      goToPage(homePath);
    };

    onBeforeMount(() => {
      if (!app.$vsf.$magento.config.state.getCustomerToken()) {
        const homePath = app.localeRoute({ name: 'home' });
        goToPage(homePath);
      }
    });

    return {
      sidebarLinkGroups,
      title,
      isOnSubpage,
      logOutUser,
      getCurrentRouteName,
      goToPage,
      getUser,
      goToTopLevelRoute,
      isMobile
    };
  },
  head(): MetaInfo {
    const pageTitle = this.$i18n.t(this.$route?.meta?.titleLabel ?? '');
    return {
      title: pageTitle as string
    };
  }
});
