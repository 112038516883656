










import type { Ref } from '@nuxtjs/composition-api';
// eslint-disable-next-line no-duplicate-imports
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  useContext,
  watch
} from '@nuxtjs/composition-api';

import { Logger } from '~/helpers/logger';
import { useAdyen } from '~/integrations/adyen/composables/useAdyen';
import useUser from '~/modules/customer/composables/useUser';
import userGetters from '~/modules/customer/getters/userGetters';

import type { SavedPaymentMethod } from './types';

export default defineComponent({
  name: 'AdyenMethodsProvider',
  emits: ['error'],
  setup(_props, { emit }) {
    const {
      getPaymentMethods,
      paymentMethods,
      error,
      loading,
      deleting,
      deleteStoredMethod
    } = useAdyen();
    const { i18n } = useContext();
    const { user } = useUser();
    const paymentMethodsRef: Ref<Array<SavedPaymentMethod>> = ref([]);
    const CARD_NUM_LENGTH = 19;
    const BASE_IMG_URL =
      'https://checkoutshopper-test.adyen.com/checkoutshopper/images/logos/';

    const iconMap = {
      paypal: {
        url: 'paypal',
        title: 'Paypal'
      },
      nocard: {
        url: 'nocard',
        title: 'No card'
      }
    };

    const getIconUrl = (brand) => {
      return [BASE_IMG_URL, brand, '.svg'].join('');
    };

    const createCardData = (
      id,
      icon,
      expiryYear = null,
      expiryMonth = null,
      maskCardNumber = null
    ) => ({
      id,
      expiryYear,
      expiryMonth,
      maskCardNumber,
      icon: {
        width: 38,
        height: 24,
        url: icon.url,
        title: icon.title
      }
    });

    const getIcon = (type, brand = '') => {
      if (type === 'scheme' && brand) {
        return {
          url: getIconUrl(brand.toLowerCase()),
          title: brand
        };
      }

      return {
        url: getIconUrl(iconMap[type].url),
        title: iconMap[type].title
      };
    };

    onMounted(async () => {
      await getPaymentMethods();

      if (paymentMethods.value?.paymentMethodsResponse?.storedPaymentMethods) {
        paymentMethodsRef.value =
          paymentMethods.value.paymentMethodsResponse.storedPaymentMethods.map(
            (card) => {
              if (card.type === 'paypal') {
                return createCardData(card.id, getIcon('paypal'));
              }

              if (card.type === 'scheme') {
                const maskedCardNumber = card.lastFour.padStart(
                  CARD_NUM_LENGTH,
                  '**** '
                );
                return createCardData(
                  card.id,
                  getIcon('scheme', card.brand),
                  Number(card.expiryYear),
                  Number(card.expiryMonth),
                  maskedCardNumber
                );
              }

              Logger.info('Unknown credit card type', card);
              return createCardData(null, getIcon('nocard'));
            }
          );
      }
    });

    const deletePaymentMethod = async (id: string) => {
      const res = await deleteStoredMethod({
        stored_payment_method_id: id,
        customer_email: userGetters.getEmailAddress(user.value),
        store_code: i18n.localeProperties.code
      });

      if (res.success) {
        paymentMethodsRef.value = paymentMethodsRef.value.filter(
          (method) => method.id !== id
        );
      }
    };

    watch(error, () => emit('error'), { deep: true });

    return {
      loading,
      deleting,
      paymentMethods: computed(() => paymentMethodsRef.value),
      deletePaymentMethod
    };
  }
});
