








import { defineComponent, onMounted } from '@nuxtjs/composition-api';
import { useCountriesStore } from '~/diptyqueTheme/stores/countries';
import {MetaInfo} from "vue-meta";
export default defineComponent({
  name: 'AccountShippingAddresses',
  components: {
    VaimoShippingAddresses: () => import('organisms/VaimoShippingAddresses.vue')
  },
  head(): MetaInfo {
    const pageTitle = this.$i18n.t(this.$route?.meta?.titleLabel ?? '');
    return {
      title: pageTitle as string
    };
  },
  setup() {
    const { getAllowedCountries } = useCountriesStore();
    onMounted(async () => {
      await getAllowedCountries();
    });
  }
});
