















import { defineComponent, useContext } from '@nuxtjs/composition-api';

import { useStore, useUiNotification } from '~/composables';

import PaymentMethodsTable from './internal/PaymentMethodsTable.vue';

export default defineComponent({
  name: 'MyPaymentMethods',
  components: {
    PaymentMethodsTable,
    GMOMethodsProvider: () =>
      import(
        /* webpackChunkName: "GMOMethodsProvider" */
        /* webpackMode: "eager" */
        './internal/GMOMethodsProvider.vue'
      ),
    AdyenMethodsProvider: () =>
      import(
        /* webpackChunkName: "AdyenMethodsProvider" */
        /* webpackMode: "eager" */
        './internal/AdyenMethodsProvider.vue'
      )
  },
  setup() {
    const { isJpStore } = useStore();
    const { send: sendNotification } = useUiNotification();
    const { i18n } = useContext();
    const methodsProvider = isJpStore.value
      ? 'GMOMethodsProvider'
      : 'AdyenMethodsProvider';

    const onError = () => {
      sendNotification({
        icon: 'error',
        id: Symbol(`delete_payment_method`),
        message: i18n.t('Something went wrong. Please try again!') as string,
        persist: false,
        type: 'danger'
      });
    };

    return {
      methodsProvider,
      onError
    };
  }
});
