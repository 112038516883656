import { useContext } from '@nuxtjs/composition-api';
import type { RawLocation } from 'vue-router';

type LinkGroup = { title: string; items: LinkGroupItem[] };
type LinkGroupItem = { label: string; link?: RawLocation; listeners?: Record<string, () => Promise<void> | void> };

export const useSidebarLinkGroups = () => {
  const {
    app: { i18n }
  } = useContext();
  const sidebarLinkGroups: LinkGroup[] = [
    {
      title: i18n.t('Personal details') as string,
      items: [
        {
          label: i18n.t('My Account Information') as string,
          link: { name: 'customer-my-profile' }
        },
        {
          label: i18n.t('My Orders') as string,
          link: { name: 'customer-order-history' }
        },
        {
          label: i18n.t('Address Book') as string,
          link: { name: 'customer-addresses-details' }
        },
        {
          label: i18n.t('My payment methods') as string,
          link: { name: 'customer-vault-card-list' }
        },
        {
          label: i18n.t('My Contact Preferences') as string,
          link: { name: 'customer-my-newsletter' }
        }
        // {
        //   label: 'My wishlist',
        //   link: { name: 'customer-my-wishlist' },
        // },
      ]
    }
  ];

  return { sidebarLinkGroups };
};
